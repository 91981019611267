@import '../../styles/mirror-partials/common';

.invitation {
  width: 44vh;
  overflow: hidden;
  animation: fadeIn .2s ease;
  animation-delay: $intro-delay + $intro-duration + .1s;
  text-align: center;

  animation-fill-mode: both;

  &.accepted,
  &.rejected {
    padding: .5em 1em;
    border-radius: $button-border-radius;
    background-color: rgba($white, .85);
    font-size: 20px;
  }

  &.accepted {
    color: $green-900;
  }

  &.rejected {
    color: $red-900;
  }
}
