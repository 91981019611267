@import '../../styles/mirror-partials/common';

.devices {
  // ensure space before end of page
  margin-bottom: 15rem;
}

ol.circle {
  margin-left: 6rem;
  list-style: none;
  counter-reset: item;

  h2 {
    margin-bottom: 1em;
  }

  h3 {
    margin-bottom: .5em;
  }

  & > li::before {
    content: 'Step ' counter(item);
    position: absolute;
    margin: -.15rem 0 .5rem -6rem;
    padding: .5rem 1rem .5rem 1rem;
    border-radius: 1.5rem;
    background: mix($green-700, $grey-600);
    color: $white;
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    counter-increment: item;
  }

  & > li {
    margin-bottom: 1.5em;
  }
}

.csv textarea {
  min-height: 8em;
}

.placeholder {
  white-space: pre;
}

li.button-list {
  $option-margin-sides: 20px;
  $option-margin-vertical: 20px;
  list-style-type: none;

  .form-option {
    display: inline-block;
    vertical-align: top;

    input {
      display: none;
    }

    label {
      display: flex;
      flex-flow: row;
      flex-grow: 1;
      flex-shrink: 1;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 80px;
      margin: 0 .25em .5em;
      // unselected item styling
      border: 4px solid $poly-linen;
      border-radius: 19px;
      opacity: .6;
      background: $white;
      box-shadow: 3px 3px 6px 0 rgba(141, 141, 141, .50);

      img {
        max-width: calc(100% - #{$option-margin-sides} - #{$option-margin-sides});
      }

      .button-list-text,
      .button-list-text-sm {
        text-align: center;
      }

      .button-list-text {
        font-size: 30px;
      }

      .button-list-text-sm {
        font-size: 20px;
      }
    }

    // selected items are flat on canvas, thus no shadow
    input:checked + label {
      border: 4px solid $poly-slate;
      border-radius: 19px;
      opacity: 1;
      box-shadow: none;
    }
  }
}
