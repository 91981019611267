.radio-input {
  .form-option {
    display: block;
  }

  & > label {
    font-weight: bold;
  }

  input {
    margin-right: .5em;
  }
}
