@import '../../styles/mirror-partials/common';

.card {
  display: inline-block;
  position: relative;
  width: 240px;
  // height set by variants such as .card.landing and .card.device
  margin-right: 2em;
  transition: top ease-out 100ms;
  animation: fadeIn 700ms forwards;
  border-radius: $card-border-radius;
  opacity: 0;
  background-image: linear-gradient(9deg, $grey-100 0%, $white 24%);
  box-shadow: 0 9px 22px 0 rgba(174, 174, 174, .24);
  color: mix($black, $green-900);
  text-align: left;
  vertical-align: top;

  > label {
    position: relative;
    top: -21px;
    border-top-left-radius: $card-border-radius;
    border-top-right-radius: $card-border-radius;
    background-color: $white;
    font-size: 20px;
    text-transform: uppercase;

    span {
      display: inline-block;

      &::first-letter {
        font-size: 26px;
      }
    }
  }

  &.device .actions {
    position: absolute;
    right: .5em;
    bottom: .5em;
    text-align: right;
  }

  &.auto {
    width: auto;
    height: auto;
  }

  &.device-section {
    width: 85%;
    height: auto;
    margin-bottom: 2em;
    padding: 2em;

    h3 {
      margin-bottom: 1em;
    }
  }

  // specific card styling should be in page-specific SCSS files

  a,
  a:visited {
    color: $link-blue;
  }

  &.label-layout {
    label {
      display: inline-block;
      width: 200px;
      font-weight: bold;
    }
  }

  /* fix z-index issue by converting it to an actual element instead of pop-up */
  .rw-popup-container {
    position: relative;
  }
}

a .card {
  &:hover {
    box-shadow: 0 9px 22px 0 rgba(174, 174, 174, .44);

    .showOnHover {
      animation: fadeIn $card-content-reveal-duration forwards;
    }
  }

  &:active {
    top: 2px;
    box-shadow: 2px 4px 6px 0 rgba(200, 200, 200, .5);
  }
}

.card .showOnHover {
  opacity: 0;
}

.card.landing {
  margin-top: 21px;
  margin-bottom: 21px;

  .content {
    height: 160px;
    overflow: hidden;

    .change {
      opacity: 0;
    }
  }

  .stat {
    opacity: 1;
    font-size: 60px;
    font-weight: 100;
    text-align: right;

    label {
      display: block;
      margin-top: -.5em;
      color: mix($black, $green-900);
      font-size: 20px;
      font-weight: 400;
    }
  }
}

a .card.landing:hover {
  // hasChangeInfo tracks if change is included in data structure, e.g. +15% since last week
  & .hasChangeInfo .stat,
  & .hasChangeInfo .change {
    transition: margin 300ms ease-in-out, opacity 800ms ease-in-out;

    animation-fill-mode: both;
  }

  & .hasChangeInfo .stat {
    opacity: .4;
  }

  & .hasChangeInfo.increase .stat {
    margin-top: -20px;
  }

  & .hasChangeInfo.decrease .stat {
    margin-top: 0;
  }

  & .hasChangeInfo.decrease .change {
    margin-top: 0;
  }

  .change {
    opacity: 1;
  }
}
