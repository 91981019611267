@import '../../styles/mirror-partials/common';

/* partner specific card layouts */
.card.configure-devices,
.card.api-access {
  width: 240px;
  height: 185px;

  & > div {
    margin: .9em auto;
    text-align: center;
  }

  h6 {
    margin-top: .85em;
    margin-bottom: .5em;
    color: $grey-700;
  }
}

.card.api-access img {
  margin-top: 16px;
}

.content {
  &.decrease .change {
    margin-top: -22px;
  }

  &.increase .change {
    margin-top: 30px;
  }

  &.increase .changePercent {
    color: $increase;

    .poly-icon {
      fill: $increase;
    }
  }

  &.increase .changeLabel {
    color: mix($black, $increase, 90%);
  }

  &.decrease .changePercent {
    color: $decrease;

    .poly-icon {
      fill: $decrease;
    }
  }

  &.decrease .changeLabel {
    color: mix($black, $decrease, 90%);
  }
}

.landing-page {
  margin: 0 auto;
  padding: .5em 3vw;
  text-align: center;
}

.greeting {
  align-items: center;
  justify-content: center;
  width: 100%;
  color: $grey-800;
  font-size: calc(10px + 1.5vh);
}

.greeting-name {
  animation: fadeInRight .4s ease-in;
  font-size: 1.75em;
}

.greeting-message {
  font-size: .85em;
  font-weight: normal;
}

@keyframes fadeInRight {
  0% {
    transform: translateX(50%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
