body {
  margin: 0;
  overflow-x: hidden;
  //background: black url("https://pocb372255d62e545bdaed876b8690de004-dev.s3.amazonaws.com/earth2-1000k-static.png") no-repeat center center fixed;
  background-size: cover;
  font-family: 'Roboto', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
