@import '../../styles/mirror-partials/common';

// see related App.scss .app-root rules
.signin {
  display: flex;
  flex-direction: column;
  margin: .25em 3em;
  padding: .5em 1em 1em 1em;
  animation: fadeIn .2s ease;
  animation-delay: $intro-delay + $intro-duration + .1s;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, .5);
  color: $black;
  font-size: calc(10px + 2vmin);
  text-align: center;

  animation-fill-mode: both;
  backdrop-filter: blur(5px);

  .title {
    margin: .5em 0;
  }

  .section {
    margin: 0 0 .25em .5em;
    font-size: large;
    text-align: left;
  }

  .divider {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    margin: .5em 0;
    color: rgba(37, 13, 13, .75);

    &:before,
    &:after {
      content: '';
      flex-grow: 1;
      height: 1px;
      margin: 0 8px;
      background: rgba(0, 0, 0, .35);
      font-size: 0;
      line-height: 0;
    }
  }

  .button {
    margin: .25em 0;
    padding: .4em .8em;
    border: none;
    border-radius: 12px;
    background-color: $black;
    box-shadow: none;
    color: $white;
    font-size: .8em;
    cursor: pointer;
  }

  .passwordless-error {
    display: flex;
    align-content: center;
    justify-content: center;
    margin: .5em;
    padding: .75em;
    border-radius: 12px;
    border-color: #f5c6cb;
    background-color: #f8d7da;
    color: $error;
    font-size: large;

    .poly-icon {
      margin: auto 0;
      fill: $error !important;
    }

    .passwordless-error-message {
      margin: 0 auto 0 1em;
      text-align: left;
      vertical-align: middle;
    }
  }

  .passwordless-form {
    width: 100%;

    .field {
      display: flex;
      position: relative; /* For the icon positioning */

      .poly-icon.label {
        position: absolute;
        margin-top: .6em;
        margin-left: 0.1em;
        fill: $grey;
        color: $grey;
      }

      .poly-icon.submit {
        width: 100%;
        margin-top: .3em;
        fill: $black;
        color: $black;
        cursor: pointer;
      }

      .poly-icon.disabled {
        fill: $grey;
        color: $grey;
        cursor: default;
      }

      .input {
        width: 100%;
        margin: .5em 0em 0 0;
        padding: .25em 0 .25em 2.5em;
        border: none;
        border-radius: 10px;
        font-size: medium;
      }
    }

    .sub {
      margin-right: 2em;
      overflow: hidden;
      font-size: medium;
      text-align: left;

      .cancel-button {
        margin-left: .5em;
        padding: 0!important;
        border: none;
        background: none!important;
        color: #069;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
