@import '../../styles/mirror-partials/common';

.pagination-summary {
  text-align: right;

  span {
    font-weight: bold;
  }
}

ul.pagination {
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
  text-align: center;

  li {
    display: inline-block;

    .button {
      margin-left: -1px;
      border: 1px solid $grey-200;
      border-radius: 0;
    }
  }

  li.page-item.active .page-link {
    border-color: $grey-400;
    background-color: mix($blue-700, $purple-300);
    color: $white;
  }

  .page-link:hover {
    background-color: #f4f4f4;
  }
}
